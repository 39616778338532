import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { graphql, Link } from "gatsby";
import noimageImg from "../../images/noimage.png"

const RecipePage = ({ data }) => (
  <Layout>
    <SEO title={data.microcmsRecipe.title} />

    <Row style={{ maxWidth: `60rem` }}>
      <Col xs={12} md={12} >
        <Card style={{ marginTop: `1rem` }}>
          <Card.Header>
            <h2>{data.microcmsRecipe.title}</h2>
          </Card.Header>
          <Card.Body >
            <Card.Text>
              <Row>
                <Col xs={12} md={12} >
                  {data.microcmsRecipe.tags.map(tags => (
                    <Link to={`/tag/${tags.id}`} style={{ margin: `0.5rem` }} key={tags.id}>
                      {`#` + tags.tagname}
                    </Link>
                  ))}
                </Col>
                <Col xs={12} md={12} style={{ height: `1rem` }}>
                </Col>
                <Col xs={12} md={6} style={{ marginBottom: `1rem` }}>
                  <Card.Img src={data.microcmsRecipe.image === null ? noimageImg : data.microcmsRecipe.image.url + `?fit=crop&w=1000&h=1000`} alt={data.microcmsRecipe.title} />
                </Col>
                <Col xs={12} md={6} >
                  <Table hover>
                    <thead>
                      <tr class="table-dark">
                        <td>
                          材料
                        </td>
                        <td>
                          数量
                        </td>
                      </tr>
                      {data.microcmsRecipe.ingredients.map(ingredients => (
                        <tr>
                          <td>
                            {ingredients.name === null ? <Link to={`/recipe/${ingredients.recipe.id}`} > {ingredients.recipe.title}</Link> : <a href={ingredients.url} target="_blank" rel="noreferrer noopener">{ingredients.group === null ? `` : ingredients.group}{ingredients.name}</a>}
                          </td>
                          <td>
                            {ingredients.quantity}
                          </td>
                        </tr>

                      ))}
                      <tr>
                        <td></td><td></td>
                      </tr>
                    </thead>
                  </Table>
                </Col>
                <Col xs={12} md={12} >
                  <Row>
                    {data.microcmsRecipe.method.map((method, i) => (
                      <Col xs={12} md={12} >
                        {i + 1}{`. `}
                        {method.process}
                      </Col>
                    ))}
                  </Row>
                </Col>

              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>

  </Layout >
)

export default RecipePage

export const query = graphql`
  query ($recipeId: String!)  {
    microcmsRecipe(recipeId: { eq: $recipeId }) {
    recipeId
    image {
      url
    }
    ingredients {
      group
      name
      quantity
      recipe {
        title
        id
      }
      url
    }
    method {
      process
    }
    tags {
      tagname
      id
    }
    title
  }
}
  `